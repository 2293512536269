import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    public loadingController: LoadingController
  ) { }

  async loading() {
    const loading = await this.loadingController.create({
      message: 'Carregando...',
    });
    return loading;
  }
}
