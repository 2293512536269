import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user:any = {}
  obj:any = {
    refresh:false
  }

  constructor() { }

  getUser(){
    return this.user;
  }

  setUser(user){
    this.user = user;
  }

  getData(id){
    return this.obj[id];
  }

  setData(id,value){
    this.obj[id] = value;
  }
}
