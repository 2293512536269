import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private router:Router,
    private api:ApiService
  ) {
    let _LASTSESSION:any = JSON.parse(localStorage.getItem('_LASTSESSION')) || null;

    if(_LASTSESSION && _LASTSESSION.time < new Date().getTime()){
      localStorage.removeItem('_LASTSESSION');
      this.router.navigate(['/']);
    }

    this.api.checarInternet();
    console.log = () => {}
  }
}
