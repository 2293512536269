import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from './user.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  urlCampanha:string='http://barbosa.ibrs.com.br:5000/api/campanha/'

  chave:string='';
  uuid:string=new Date().getTime()+'';

  conectado:boolean=true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private user: UserService,
    private tools: ToolsService
  ) {}

  checarInternet() {
    window.addEventListener("online", () => {
      // this.network.onConnect().subscribe(() => {
      this.conectado = true;
      console.log('Conectado a internet!');
    });

    window.addEventListener("offline", () => {
      // this.network.onDisconnect().subscribe(() => {
      this.conectado = false;
      console.log('Sem conexão com a internet :-(');
    });
  }

  getStatusInternet() {
    return this.conectado;
  }

  async campanha(endpoint,data,callback){
    var url = this.urlCampanha+endpoint;

    data.app_key="byDwKBazAbwWlzdcHZymBDOM";
    data.app_secret="nPUnkOoUZdpT";

    let loading = await this.tools.loading();
    await loading.present();

    return this.http.post(url,data).subscribe(result=>{
      loading.dismiss();
      callback(result);
    },(err)=>{
      if(!this.conectado){
        alert('Sem conexão. Tente novamente.');
      }
      loading.dismiss();
      callback({err:true})
    });
  }

  async post(url,data,callback){
    let loading = await this.tools.loading();
    await loading.present();

    return this.http.post(url,data).subscribe(result=>{
      loading.dismiss();
      callback(result);
    },(err)=>{});
  }
}
